/* eslint-disable react/jsx-filename-extension */
import React from "react";
import { graphql } from "gatsby";
import { mergeCraftPreview, resolveImage, resolveTeam } from "@dataResolvers";
import { Contact as PageTemplate } from "@pageTemplates";

export const query = graphql`
  query ContactQuery {
    craft {
      entry(section: "contact") {
        title
        url
        slug
        ... on Craft_contact_contact_Entry {
          #meta
          metaTitle
          metaDescription
          metaImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          #hero
          heroLabel
          heroTitle
          heroImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          heroVideoUrl
          videoAspectRatio
          #team
          #team
          aboutTeamHeader
          aboutTeamDescription
          #form
          formHeadline
          formText
          formImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
        }
      }
      entries(section: "teamMembers") {
        ... on Craft_teamMembers_teamMembers_Entry {
          teamMember {
            ... on Craft_teamMember_teamMember_BlockType {
              enabled
              uid
              image {
                width
                height
                hasFocalPoint
                slug
                status
                title
                focalPoint
                url
              }
              fullName
              position
              email
              contactInfo {
                linkedin
              }
            }
          }
        }
      }
    }
  }
`;

const previewQuery = `query ContactQuery {
  craft {
    entry(section: "contact") {
      title
      url
      slug
      ... on Craft_contact_contact_Entry {
        #meta
        metaTitle
        metaDescription
        metaImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        #hero
        heroLabel
        heroTitle
        heroImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        heroVideoUrl
        videoAspectRatio
        #team
        #team
        aboutTeamHeader
        aboutTeamDescription
        #form
        formHeadline
        formText
        formImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
      }
    }
    entries(section: "teamMembers") {
      ... on Craft_teamMembers_teamMembers_Entry {
        teamMember {
          ... on Craft_teamMember_teamMember_BlockType {
            enabled
            uid
            image {
              width
              height
              hasFocalPoint
              slug
              status
              title
              focalPoint
              url
            }
            fullName
            position
            email
            contactInfo {
              linkedin
            }
          }
        }
      }
    }
  }
}`;

const dataResolver = ({ craft }) => {
  const {
    title,
    url,
    slug,
    // meta
    metaTitle,
    metaDescription,
    metaImage,
    // hero
    heroLabel,
    heroTitle,
    heroImage,
    // form
    formHeadline,
    formText,
    formImage,
    // team
    aboutTeamHeader,
    aboutTeamDescription,
  } = craft.entry;

  const teamMembers = craft.entries;

  return {
    title,
    hero: {
      label: heroLabel,
      heading: heroTitle,
      image: resolveImage(heroImage),
    },
    team: {
      heading: aboutTeamHeader,
      copy: aboutTeamDescription,
      cards: resolveTeam(teamMembers),
    },
    form: {
      heading: formHeadline,
      copy: formText,
      image: resolveImage(formImage),
    },
    meta: {
      title,
      url,
      slug,
      metaTitle,
      metaDescription,
      metaImage: resolveImage(metaImage || heroImage),
    },
  };
};

const QueryContainer = ({ data, pageContext }) => {
  const { slug: _slug } = pageContext;
  const merged = mergeCraftPreview(data, previewQuery, {
    slug: _slug,
    site: process.env.GATSBY_CRAFT_SITE_HANDLE,
  });

  // When previewing unpublished pages, merged will be empty until
  // the preview reqeust has finished, so returning null to avoid
  // component errors about missing content.
  if (!merged) {
    return null;
  }

  const resolvedData = dataResolver(merged);

  return <PageTemplate data={resolvedData} />;
};

export default QueryContainer;
